import { Computer, PhoneAndroid, TabletAndroid } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  CircularProgress,
  InputAdornment,
  Typography,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import * as React from 'react';
import {
  fetchHighlights,
  updateHighlightGroup,
  fetchArticles,
} from 'lib/Service/Articles';
import { AxiosError } from 'axios';
import { SnackbarContext } from 'components';
import {
  HighlightGroupResponse,
  ArticlesResponse,
  Article,
} from 'lib/Model/Article';
import { useDebounce } from 'hooks';
import { format } from 'date-fns';
import { DateFormat } from 'config';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableArticle } from './DraggableArticle';

const HighlightsContainer = styled(CardContent)(
  ({ theme }) => `
  max-width: ${990 + 20}px;
`,
);

const TopStoryRow = styled(CardContent)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 0;
  flex-wrap: wrap;
`,
);

interface Props {
  title: string;
  group: 'main_headlines' | 'editors_choice';
}

export const HighlightsSortable: React.FC<Props> = ({ title, group }) => {
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [previewMediaQuery, setPreviewMediaQuery] = React.useState<
    'desktop' | 'tablet' | 'mobile'
  >('desktop');

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    if (isMobile || isTablet) {
      setPreviewMediaQuery(isMobile ? 'mobile' : 'tablet');
    }
  }, [isMobile, isTablet, setPreviewMediaQuery]);

  const [searchLoading, setSearchLoading] = React.useState(false);
  const [textSearch, setTextSearch] = React.useState('');
  const debouncedTextSearch = useDebounce(textSearch, 500);

  const [queryResponse, setQueryResponse] = React.useState<
    HighlightGroupResponse | undefined
  >();
  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchHighlights(group)
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, setQueryResponse, group]);

  const [pristine, setPristine] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState<
    number | undefined
  >();
  const [newSelectedArticle, setNewSelectedArticle] =
    React.useState<Article | null>(null);

  const [articlesQueryResponse, setArticlesQueryResponse] = React.useState<
    ArticlesResponse | undefined
  >();

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    if (debouncedTextSearch.length <= 3) {
      return;
    }
    setSearchLoading(true);
    fetchArticles({ query: debouncedTextSearch }, 1, 10)
      .then((d) => {
        setSearchLoading(false);
        setArticlesQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setSearchLoading, setArticlesQueryResponse, debouncedTextSearch]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title={title}
          action={
            !isMobile &&
            !isTablet && (
              <ToggleButtonGroup
                value={previewMediaQuery}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newAlignment: 'desktop' | 'tablet' | 'mobile' | null,
                ) => {
                  setPreviewMediaQuery(newAlignment ?? 'desktop');
                }}
                aria-label="text alignment"
              >
                <ToggleButton value="mobile">
                  <PhoneAndroid />
                </ToggleButton>
                <ToggleButton value="tablet">
                  <TabletAndroid />
                </ToggleButton>
                <ToggleButton value="desktop">
                  <Computer />
                </ToggleButton>
              </ToggleButtonGroup>
            )
          }
        />
        <DndProvider backend={HTML5Backend}>{getLayout()}</DndProvider>
        <CardActions>
          {saveLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              color="primary"
              disabled={pristine}
              onClick={async () => {
                if (!queryResponse || !queryResponse.data) {
                  return;
                }
                setSaveLoading(true);
                await updateHighlightGroup(
                  group,
                  queryResponse.data.map((d) => ({
                    order: d.order,
                    article_id: d.article_id,
                  })),
                )
                  .then(() => {
                    snackbar.success(
                      'Highlight group has been saved successfully',
                    );
                  })
                  .catch((error) => {
                    if (
                      error instanceof AxiosError &&
                      error.response?.data.message
                    ) {
                      snackbar.error(error.response.data.message);
                      return;
                    }
                    snackbar.error('Unknown error while saving');
                  })
                  .finally(() => {
                    setSaveLoading(false);
                  });
              }}
            >
              Save changes
            </Button>
          )}
        </CardActions>
      </Card>
      <Dialog maxWidth="sm" fullWidth open={selectedIndex !== undefined}>
        <DialogTitle>Select an article</DialogTitle>
        <DialogContent>
          <Autocomplete
            value={newSelectedArticle}
            selectOnFocus
            blurOnSelect
            options={
              !articlesQueryResponse || !articlesQueryResponse.data
                ? []
                : articlesQueryResponse.data
            }
            noOptionsText={
              searchLoading ? 'Loading...' : 'Search to load options'
            }
            getOptionKey={(o) => o.id}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            getOptionLabel={(option) => option.title ?? `Article ${option.id}`}
            renderOption={(props, option) => {
              const { ...optionProps } = props;
              return (
                <Box
                  key={`article_${option.id.toString()}`}
                  sx={{
                    borderRadius: '8px',
                    margin: '5px',
                    [`&.${autocompleteClasses.option}`]: {
                      padding: '8px 20px',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'flex-start',
                    },
                  }}
                  component="li"
                  {...optionProps}
                >
                  <Typography variant="h6">
                    {option.title ?? `Article ${option.id}`}
                  </Typography>
                  {option.subheadline && (
                    <Typography variant="body1">
                      {option.subheadline}
                    </Typography>
                  )}
                  {option.date && (
                    <Typography variant="caption">
                      {format(option.date, DateFormat.LONG)}
                    </Typography>
                  )}
                </Box>
              );
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Find an article"
                fullWidth
                margin="normal"
                variant="outlined"
                autoFocus
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchLoading && <CircularProgress size={24} />}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setTextSearch(e.currentTarget.value);
                }}
              />
            )}
            fullWidth
            onChange={(e, option) => {
              if (!option) {
                return;
              }
              setNewSelectedArticle(option);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedIndex(undefined)} color="inherit">
            Cancel
          </Button>
          <Button
            disabled={!newSelectedArticle}
            color="primary"
            onClick={() => {
              if (
                !newSelectedArticle ||
                selectedIndex === undefined ||
                !queryResponse
              ) {
                return;
              }
              const workingList = _changeItemArticle(
                selectedIndex,
                newSelectedArticle,
              );
              setQueryResponse({
                ...queryResponse,
                data: workingList,
              });
              setPristine(false);
              setSelectedIndex(undefined);
              setNewSelectedArticle(null);
            }}
          >
            Select article
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  function getLayout() {
    if (loading) {
      return <Skeleton variant="rectangular" height={150} />;
    }

    if (!queryResponse) {
      return null;
    }

    if (group === 'main_headlines') {
      // Top stories layout
      if (previewMediaQuery === 'mobile') {
        return (
          <HighlightsContainer style={{ maxWidth: 500 }}>
            <TopStoryRow>{renderStory(0, 400, '100%')}</TopStoryRow>
            <TopStoryRow>{renderStory(1, undefined, '100%')}</TopStoryRow>
            <TopStoryRow>{renderStory(2, undefined, '100%')}</TopStoryRow>
            <TopStoryRow>{renderStory(3, undefined, '100%')}</TopStoryRow>
            <TopStoryRow>{renderStory(4, undefined, '100%')}</TopStoryRow>
            <TopStoryRow>{renderStory(5, undefined, '100%')}</TopStoryRow>
          </HighlightsContainer>
        );
      } else if (previewMediaQuery === 'tablet') {
        return (
          <HighlightsContainer>
            <TopStoryRow>{renderStory(0, 300, '420px')}</TopStoryRow>
            <TopStoryRow>
              {renderStory(1, 150, '200px')}
              {renderStory(2, 150, '200px')}
            </TopStoryRow>
            <TopStoryRow>
              {renderStory(3, 150, '200px')}
              {renderStory(4, 150, '200px')}
              {renderStory(5, 150, '200px')}
            </TopStoryRow>
          </HighlightsContainer>
        );
      } else {
        return (
          <HighlightsContainer>
            <TopStoryRow>
              {renderStory(0, 400, '620px', 32)}
              <div
                style={{
                  flexBasis: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                }}
              >
                {renderStory(1)}
                {renderStory(2)}
              </div>
            </TopStoryRow>
            <TopStoryRow>
              {renderStory(3, undefined, '300px')}
              {renderStory(4, undefined, '300px')}
              {renderStory(5, undefined, '300px')}
            </TopStoryRow>
          </HighlightsContainer>
        );
      }
    }

    // Editor's choice layout
    if (previewMediaQuery === 'mobile') {
      return (
        <HighlightsContainer>
          <TopStoryRow>{renderStory(0, undefined, '100%')}</TopStoryRow>
          <TopStoryRow>{renderStory(1, undefined, '100%')}</TopStoryRow>
          <TopStoryRow>{renderStory(2, undefined, '100%')}</TopStoryRow>
          <TopStoryRow>{renderStory(3, undefined, '100%')}</TopStoryRow>
        </HighlightsContainer>
      );
    } else if (previewMediaQuery === 'tablet') {
      return (
        <HighlightsContainer>
          <TopStoryRow>
            {renderStory(0, 250, '300px')}
            {renderStory(1, 250, '300px')}
          </TopStoryRow>
          <TopStoryRow>
            {renderStory(2, 250, '300px')}
            {renderStory(3, 250, '300px')}
          </TopStoryRow>
        </HighlightsContainer>
      );
    } else {
      return (
        <HighlightsContainer>
          <TopStoryRow>
            {renderStory(0, 250, '300px')}
            {renderStory(1, 250, '300px')}

            <div
              style={{
                flexBasis: 300,
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              {renderStory(2, 115, undefined, 12)}
              {renderStory(3, 115, undefined, 12)}
            </div>
          </TopStoryRow>
        </HighlightsContainer>
      );
    }
  }

  function renderStory(
    index: number,
    height?: number,
    flexBasis?: string,
    fontSize?: number,
  ) {
    if (!queryResponse || !queryResponse.data) {
      return null;
    }

    const story = queryResponse.data[index];
    const isDuplicate =
      queryResponse.data.find(
        (f) => f.id !== story.id && f.article_id === story.article_id,
      ) !== undefined;

    return (
      <DraggableArticle
        onEdit={(editIndex) => {
          setSelectedIndex(editIndex);
        }}
        showDuplicateWarning={isDuplicate}
        slotIndex={index}
        article={story.article}
        flexBasis={flexBasis}
        fontSize={fontSize}
        height={height}
        onDrop={() => {
          setPristine(false);
          setQueryResponse((q) => {
            if (!q || !q.data) {
              return q;
            }
            const swapLeft = (window as any).swapLeft;
            const swapRight = (window as any).swapRight;
            const leftObj = q.data[swapLeft];
            const rightObj = q.data[swapRight];

            const workingList = q.data.slice();

            workingList[swapRight] = {
              id: leftObj.id,
              article_id: leftObj.article_id,
              article: leftObj.article,
              order: leftObj.order,
            };

            workingList[swapLeft] = {
              id: rightObj.id,
              article_id: rightObj.article_id,
              article: rightObj.article,
              order: rightObj.order,
            };

            // clean up order
            for (let i = 0; i < workingList.length; i++) {
              workingList[i].order = i;
            }

            return {
              ...q,
              data: workingList,
            };
          });
        }}
      />
    );
  }

  function _changeItemArticle(index: number, article: Article) {
    const workingList = queryResponse!.data!.slice();
    if (!workingList[index]) {
      workingList[index] = {
        article_id: article.id,
        order: index,
        article: article,
        id: -1,
      };
    } else {
      workingList[index].article = article;
      workingList[index].article_id = article.id;
    }
    return workingList;
  }
};
