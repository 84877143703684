import { Add, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { PageableTable, PageableTableRow } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { useDebounce } from 'hooks';
import {
  ArticleStatus,
  articleStatusToColor,
  articleStatusToFriendly,
} from 'lib/Helper/Enums';
import { ArticlesResponse } from 'lib/Model/Article';
import { Author } from 'lib/Model/Author';
import { Tag } from 'lib/Model/Tag';
import { fetchArticles } from 'lib/Service/Articles';
import { fetchAuthors } from 'lib/Service/Authors';
import { fetchTags } from 'lib/Service/Tags';
import * as React from 'react';

const StyledSkeleton = styled(Skeleton)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(1)};
`,
);

export const ArticlesView: React.FC = () => {
  const theme = useTheme();

  const autocompleteLimit = 9999;
  const debounceSetting = 800;

  const [textSearch, setTextSearch] = React.useState('');
  const debouncedTextSearch = useDebounce(textSearch, debounceSetting);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [queryResponse, setQueryResponse] = React.useState<
    ArticlesResponse | undefined
  >();

  const [loadingAuthors, setLoadingAuthors] = React.useState(false);
  const [authors, setAuthors] = React.useState<Author[]>([]);
  const [selectedAuthors, setSelectedAuthors] = React.useState<Author[]>([]);
  const debouncedAuthors = useDebounce(selectedAuthors, debounceSetting);

  const [loadingTags, setLoadingTags] = React.useState(false);
  const [scheduledOnly, setScheduledOnly] = React.useState(false);
  const debouncedScheduledOnly = useDebounce(scheduledOnly, debounceSetting);

  const [tags, setTags] = React.useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([]);
  const debouncedTags = useDebounce(selectedTags, 500);

  const statuses = Object.values(ArticleStatus).filter(
    (a) => !isNaN(Number(a)),
  );
  const [selectedStatuses, setSelectedStatuses] = React.useState<
    ArticleStatus[]
  >([]);
  const debouncedStatuses = useDebounce(selectedStatuses, debounceSetting);

  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<PageableTableRow[]>([]);

  const fetchAuthorData = React.useCallback(() => {
    setLoadingAuthors(true);
    fetchAuthors('', 0, autocompleteLimit, false, [])
      .then((d) => {
        setLoadingAuthors(false);
        setAuthors(d.data ?? []);
      })
      .catch((e) => {
        setLoadingAuthors(false);
      });
  }, [setAuthors, setLoadingAuthors]);

  const fetchTagData = React.useCallback(() => {
    setLoadingTags(true);
    fetchTags('', 0, autocompleteLimit)
      .then((d) => {
        setLoadingTags(false);
        setTags(d.data ?? []);
      })
      .catch((e) => {
        setLoadingTags(false);
      });
  }, [setTags, setLoadingTags, autocompleteLimit]);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchArticles(
      {
        query: debouncedTextSearch,
        authorIds: debouncedAuthors.length
          ? debouncedAuthors.map((a) => a.id)
          : undefined,
        tagIds: debouncedTags.length
          ? debouncedTags.map((a) => a.id)
          : undefined,
        statusIds: debouncedStatuses.length ? debouncedStatuses : undefined,
        scheduledOnly: debouncedScheduledOnly,
      },
      pageNumber + 1,
      pageSize,
    )
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [
    setLoading,
    setQueryResponse,
    pageNumber,
    pageSize,
    debouncedTextSearch,
    debouncedAuthors,
    debouncedStatuses,
    debouncedTags,
    debouncedScheduledOnly,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    fetchAuthorData();
    fetchTagData();
  }, [fetchAuthorData, fetchTagData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'photo',
        label: '',
        sortable: false,
        props: {
          style: { width: 100 },
        },
      },
      {
        key: 'heading',
        label: '',
        sortable: false,
      },
      {
        key: 'date',
        label: '',
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ] as PageableTableHeader[];
  }, []);

  React.useEffect(
    () =>
      setRows(
        queryResponse?.data.map((article) => {
          return {
            key: `article_${article.id}`,
            cells: [
              {
                key: 'photo',
                display: (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      backgroundColor: '#eee',
                      backgroundSize: 'cover',
                      backgroundImage: article.cover
                        ? `url('${article.cover.url}')`
                        : undefined,
                    }}
                  />
                ),
              },
              {
                key: 'heading',
                display: (
                  <React.Fragment>
                    <a
                      href={`/articles/${article.id}`}
                      style={{ color: 'inherit' }}
                    >
                      <Typography variant="h6">{article.title}</Typography>
                    </a>
                    {article.subheadline ? (
                      <Typography variant="body1">
                        {article.subheadline}
                      </Typography>
                    ) : null}
                  </React.Fragment>
                ),
              },
              {
                key: 'date',
                display:
                  article.date &&
                  format(article.date, DateTimeFormat.MEDIUM_FRIENDLY),
              },
              {
                key: 'actions',
                props: {
                  style: {
                    textAlign: 'right',
                  },
                },
                display: (() => {
                  if (!article.status) {
                    return;
                  }
                  const color = articleStatusToColor(article.status);
                  return (
                    <Chip
                      variant="outlined"
                      style={{
                        borderColor: color,
                        borderWidth: 2,
                        color: color,
                      }}
                      label={articleStatusToFriendly(article.status)}
                    />
                  );
                })(),
              },
            ],
          } as PageableTableRow;
        }) ?? [],
      ),
    [queryResponse, setRows, theme],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card style={{ marginBottom: theme.spacing(2) }}>
          <CardHeader
            title="Browse articles"
            action={
              <IconButton href="/articles/compose" color="primary">
                <Add />
              </IconButton>
            }
          />
          <Toolbar>
            <Grid container columnSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Type here to find articles..."
                  onChange={(e) => setTextSearch(e.target.value ?? '')}
                  value={textSearch}
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  value={selectedTags}
                  disabled={loadingTags}
                  selectOnFocus
                  multiple
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select tags"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  options={tags}
                  getOptionLabel={(o) => o.name ?? 'N/A'}
                  getOptionKey={(o) => o.id}
                  clearOnBlur
                  clearOnEscape
                  autoHighlight
                  onChange={(e, newSelection) => {
                    if (!newSelection) {
                      return;
                    }
                    setSelectedTags(newSelection);
                    return;
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  value={selectedAuthors}
                  disabled={loadingAuthors}
                  selectOnFocus
                  multiple
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select authors"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  options={authors}
                  getOptionLabel={(o) => o.display_name ?? 'N/A'}
                  getOptionKey={(o) => o.id}
                  clearOnBlur
                  clearOnEscape
                  autoHighlight
                  onChange={(e, newSelection) => {
                    if (!newSelection) {
                      return;
                    }
                    setSelectedAuthors(newSelection);
                    return;
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  value={selectedStatuses}
                  selectOnFocus
                  multiple
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select statuses"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  options={statuses}
                  getOptionLabel={(o) => articleStatusToFriendly(o)}
                  getOptionKey={(o) => o}
                  clearOnBlur
                  clearOnEscape
                  autoHighlight
                  onChange={(e, newSelection) => {
                    if (!newSelection) {
                      return;
                    }
                    setSelectedStatuses(newSelection as ArticleStatus[]);
                    return;
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduledOnly}
                      onChange={() => {
                        setScheduledOnly((s) => !s);
                      }}
                    />
                  }
                  label="Show scheduled articles only"
                />
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            {loading ? (
              <React.Fragment>
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
                <StyledSkeleton variant="rectangular" />
              </React.Fragment>
            ) : (
              <PageableTable
                columns={columns}
                rows={rows}
                pageNumber={pageNumber}
                rowCount={queryResponse?.meta?.total ?? rows.length}
                onChangePageSize={setPageSize}
                onChangePage={setPageNumber}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
