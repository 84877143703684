import { ArticleStatus } from 'lib/Helper/Enums';
import { ResponseMeta } from './ResponseMeta';

export interface Article {
  id: number;
  version: string;
  date?: Date;
  user_id: number; // TODO: change to actual entity
  status?: number;
  title?: string;
  body?: string;
  subheadline?: string;
  slug: string;
  created: Date;
  modified: Date;
  cover?: ArticleAttachment;
  attachments?: ArticleAttachment[];

  disallow_comments?: boolean;
  no_crawlers?: boolean;
  is_sponsored?: boolean;
  is_free?: boolean;
  is_polly?: boolean;

  is_breaking_news?: boolean;
  ad_keyword?: string;
  redirect_url?: string;

  contributors?: ArticleContributor[];
  articles_authors?: ArticleAuthor[];
  articles_tags?: ArticleTag[];
}
export interface ArticleAuthor {
  id: number;
  article_id: number;
  author_id: number;
  position: number;
}

export interface ArticleContributor {
  id: number;
  credits: string;
}
export interface ArticleTag {
  id: number;
  article_id: number;
  tag_id: number;
  position: number;
}
export interface ArticlesResponse {
  success: boolean;
  message: string;
  data: Article[];
  meta: ResponseMeta;
}
export interface ArticleAttachment {
  id: number;
  uuid: string;
  type: number;
  is_cover: boolean;
  source: string;
  name: string;
  caption?: string;
  prepared: boolean;
  filename?: string;
  article_id: number;
  order?: number;
  url?: string;
}

export interface ArticleHighlightGroup {
  id: number;
  article_id: number;
  order: number;
  article: Article;
}

export interface HighlightGroupResponse {
  success: boolean;
  message: string;
  id: number;
  data: ArticleHighlightGroup[];
  meta: ResponseMeta;
}

export function getNewArticle(): Article {
  return {
    id: -1,
    version: 'v5', // TODO: check about this
    user_id: -1, // TODO: check about this
    status: ArticleStatus.DRAFT,
    slug: '',
    is_free: true, // Defaulting to true
    created: new Date(),
    modified: new Date(),
  };
}
