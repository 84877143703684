import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { ExpandMore } from '@mui/icons-material';
import { Role, getNewRole } from 'lib/Model/Role';
import { Permission } from 'lib/Model/Permission';
import { createRole, updateRole } from 'lib/Service/Roles';
import { AxiosError } from 'axios';
import { generateSeededColour } from 'lib';
import { styled } from '@mui/system';
import { fetchPermissions } from 'lib/Service/Permissions';

interface Props {
  role: Role | undefined;
  open: boolean;
  onClose: () => void;
  onSaved: (savedRole: Role) => void;
}

const StyledAvatar = styled(Avatar)(
  ({ theme }) => `
      width: 60px;
      height: 60px;
      margin-right: ${theme.spacing(1)};
  `,
);

export const RoleDrawer: React.FC<Props> = ({
  role: selectedRole,
  onClose,
  open,
  onSaved,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [permissions, setPermissions] = React.useState<Permission[]>(
    [] as Permission[],
  );
  const [loadingPermissions, setLoadingPermissions] = React.useState(false);
  const fetchPermissionData = React.useCallback(() => {
    setLoadingPermissions(true);
    fetchPermissions('', 0, 9999)
      .then((d) => {
        setLoadingPermissions(false);
        setPermissions(d.data ?? []);
      })
      .catch((e) => {
        setLoadingPermissions(false);
      });
  }, [setPermissions, setLoadingPermissions]);

  const [loading, setLoading] = React.useState(false);
  const [editingRole, setEditingRole] = React.useState<Role>(
    selectedRole ?? getNewRole(),
  );

  React.useEffect(() => {
    setEditingRole(selectedRole ?? getNewRole());
  }, [selectedRole, setEditingRole]);

  React.useEffect(() => {
    fetchPermissionData();
  }, [fetchPermissionData]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => {
        onClose();
      }}
    >
      <DelayedLinearProgress loading={loading} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          setLoading(true);
          try {
            if (selectedRole) {
              await updateRole(editingRole);
            } else {
              await createRole(editingRole);
            }
            onSaved(editingRole);
            onClose();
            snackbar.success('Changes saved successfully');
          } catch (error) {
            if (error instanceof AxiosError && error.response?.data.message) {
              snackbar.error(error.response.data.message);
              return;
            }
            snackbar.error('Unknown error while saving');
          } finally {
            setLoading(false);
          }
        }}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            <StyledAvatar
              style={{
                backgroundColor: `#${generateSeededColour({
                  seed: editingRole?.name.length
                    ? editingRole.name
                    : 'New role',
                })}`,
              }}
            >
              &nbsp;
            </StyledAvatar>
            <Typography variant="h6">
              {editingRole?.id === -1 ? 'Add' : 'Edit'} role
            </Typography>
          </Box>
          <TextField
            autoFocus
            fullWidth
            label="Role name"
            InputLabelProps={{ shrink: Boolean(editingRole?.name) }}
            onChange={(e) => {
              if (!editingRole) {
                return;
              }
              setEditingRole((r) => ({
                ...r!,
                name: e.target?.value ?? '',
              }));
            }}
            value={editingRole?.name ?? ''}
            required
            margin="normal"
          />
          <Accordion style={{ marginTop: 16 }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Permissions{' '}
              <Chip
                label={`${editingRole?.permissions?.length ?? '0'}/${
                  permissions.length
                }`}
                size="small"
                style={{ marginLeft: 4 }}
              />
            </AccordionSummary>
            <AccordionDetails>
              {loadingPermissions ? (
                <Skeleton variant="rectangular" style={{ height: 200 }} />
              ) : (
                <List>
                  {permissions.map((p, index) => {
                    const thisPermission = editingRole?.permissions?.find(
                      (rp) => rp.id === p.id, // TODO: check comparison based on what we receive from permissions
                    );
                    return (
                      <ListItem
                        key={`permission_${index}`}
                        onClick={(e) => {
                          setEditingRole((r) => {
                            const newPermissions =
                              r.permissions?.slice() ?? ([] as Permission[]);
                            const existingPermissionIndex =
                              newPermissions.findIndex((fp) => fp.id === p.id);

                            if (existingPermissionIndex >= 0) {
                              newPermissions.splice(existingPermissionIndex, 1);
                            } else {
                              newPermissions.push(p);
                            }
                            return {
                              ...r,
                              permissions: newPermissions,
                            };
                          });
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox checked={Boolean(thisPermission)} />
                        </ListItemIcon>
                        <ListItemText>{p.name}</ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </AccordionDetails>
          </Accordion>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              paddingTop: 8,
            }}
          >
            <Button
              type="submit"
              variant="text"
              color="primary"
              disabled={loading}
            >
              Save
            </Button>{' '}
            <Button
              type="button"
              variant="text"
              color="inherit"
              onClick={() => onClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};
