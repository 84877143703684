import React from 'react';
import { DateTimeFormat } from 'config';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Article } from 'lib/Model/Article';
import { DeleteForever, EditCalendar, RemoveRedEye } from '@mui/icons-material';
import { format } from 'date-fns';
import {
  ArticleStatus,
  articleStatusToColor,
  articleStatusToFriendly,
} from 'lib/Helper/Enums';
import { ArticlePreview } from './ArticlePreview';

interface Props {
  article?: Article;
  onDelete: () => void;
  onChangeStatus: (status: number) => void;
  onChangePublishDate: (date: Date) => void;
  onSave: () => void;
  loading: boolean;
}

export const ArticlePublishBar: React.FC<Props> = ({
  article,
  onDelete,
  onChangeStatus,
  onChangePublishDate,
  onSave,
  loading,
}) => {
  const today = React.useMemo(() => new Date(), []);
  const [showEditPublishDate, setShowEditPublishDate] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);

  if (!article) {
    return null;
  }

  const isScheduled = article && article.date ? article.date > today : false;

  // TODO: To check with David publishing logic
  const isPublished = article && article.date;
  const color =
    article && article.status
      ? articleStatusToColor(article.status)
      : undefined;

  return (
    <React.Fragment>
      <AppBar
        variant="elevation"
        position="fixed"
        color="default"
        style={{
          bottom: 0,
          top: 'auto',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
          padding: 8,
          gap: 32,
        }}
      >
        <div>
          {showEditPublishDate ? (
            <input
              type="datetime-local"
              style={{
                padding: 7,
                border: '1px solid #ccc',
              }}
              value={
                article &&
                article.date &&
                format(article.date, DateTimeFormat.RFC3339)
              }
              autoFocus
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                onChangePublishDate(newDate);
              }}
            />
          ) : (
            <React.Fragment>
              {isPublished && isScheduled && (
                <Typography variant="caption">
                  Scheduled for publishing on
                </Typography>
              )}
              {isPublished && !isScheduled && (
                <Typography variant="caption">Published on</Typography>
              )}
              <Typography
                variant="body1"
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  position: 'relative',
                  top: -5,
                }}
              >
                {article &&
                  article.date &&
                  format(article.date, DateTimeFormat.LONG_FRIENDLY)}
              </Typography>
            </React.Fragment>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Schedule">
            <Button
              color="secondary"
              variant="outlined"
              disabled={loading}
              onClick={() => setShowEditPublishDate((editDate) => !editDate)}
            >
              <EditCalendar />
            </Button>
          </Tooltip>
          <FormControl size="small" style={{ marginLeft: 4, minWidth: 150 }}>
            <Select
              disabled={loading}
              value={(article.status ?? 1).toString()}
              label=""
              onChange={(event: SelectChangeEvent) => {
                onChangeStatus(parseInt(event.target.value, 10));
              }}
              style={{
                color: color,
                fontWeight: 500,
              }}
              SelectDisplayProps={{
                style: {
                  paddingTop: 6.5,
                  paddingBottom: 6.5,
                },
              }}
            >
              {Object.values(ArticleStatus)
                .filter((a) => !isNaN(Number(a)))
                .map((key) => (
                  <MenuItem value={key} key={key}>
                    {articleStatusToFriendly(key)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Button
            disabled={loading}
            color="primary"
            variant="outlined"
            style={{ marginLeft: 4 }}
            onClick={() => {
              onSave();
              setShowEditPublishDate(false);
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
          {article && article.id > 0 && (
            <React.Fragment>
              <Tooltip title="Preview">
                <Button
                  disabled={loading}
                  color="info"
                  variant="outlined"
                  style={{ marginLeft: 4 }}
                  onClick={() => setShowPreview(true)}
                >
                  <RemoveRedEye />
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  disabled={loading}
                  color="error"
                  variant="outlined"
                  style={{ marginLeft: 4 }}
                  onClick={() => onDelete()}
                >
                  <DeleteForever />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      </AppBar>
      {article && (
        <Dialog
          open={showPreview}
          fullScreen
          onClose={() => {
            setShowPreview(false);
          }}
        >
          <ArticlePreview id={article.id} />
          <DialogActions>
            <Button
              onClick={() => {
                setShowPreview(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
