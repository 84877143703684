import { getApiClient } from 'lib/Helper/Api';
import { RolesResponse } from 'lib/Model/Role';

export function fetchPermissions(
  search: string,
  pageNumber: number,
  itemsPerPage: number,
): Promise<RolesResponse> {
  return getApiClient()
    .get(`/api/permissions`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        query: search,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      return d.data;
    });
}
