import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import update from 'immutability-helper';
import { Config } from 'config';
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Article,
  ArticleAuthor,
  ArticleTag,
  ArticleAttachment,
  getNewArticle,
} from 'lib/Model/Article';
import {
  deleteArticle,
  fetchArticle,
  updateArticle,
  lockArticle,
} from 'lib/Service/Articles';
import { Author } from 'lib/Model/Author';
import { fetchAuthors } from 'lib/Service/Authors';
import {
  Close,
  Delete,
  Edit,
  FilePresent,
  MusicNote,
  Refresh,
  LocalOffer as TagIcon,
  VideoCameraBack,
} from '@mui/icons-material';
import { generateSeededColour, slug } from 'lib';
import { Tag } from 'lib/Model/Tag';
import { fetchTags } from 'lib/Service/Tags';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { AxiosError } from 'axios';
import { SnackbarContext } from 'components';
import { ArticlePublishBar } from './ArticlePublishBar';
import { AttachmentDialog } from './AttachmentDialog';
import { ArticleStatus, AttachmentType } from 'lib/Helper/Enums';

import 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin';

import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

import {
  CustomIcons,
  CustomPluginAttach,
  CustomPluginAudio,
  CustomPluginIFrame,
  CustomPluginInstagram,
  CustomPluginJwPlayer,
  CustomPluginQuote,
  CustomPluginTikTok,
  CustomPluginFacebook,
  CustomPluginTweet,
  CustomPluginYoutube,
  CustomPluginCompareSlider,
  CustomPluginEmailSubscription,
  CustomPluginRelated,
  CustomPluginSlider,
} from 'components/TinyMcePlugins';
import slugify from 'slugify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SortableListItem } from './SortableListItem';
import { ArticleLock } from 'lib/Model/ArticleLock';
import { getUserData } from 'lib/Helper/Auth';
import { ArticleLockedBar } from './ArticleLockedBar';

interface ListOption {
  value: number;
  label: string;
}

export const ArticleEditor: React.FC<
  RouteComponentProps<{
    id?: string;
  }>
> = ({ match }) => {
  const { id } = match.params;

  // Useful for identifying locks
  const user = getUserData();

  const snackbar = React.useContext(SnackbarContext);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [loadingAuthors, setLoadingAuthors] = React.useState(false);
  const [loadingTags, setLoadingTags] = React.useState(false);
  const [saveArticleLoading, setSaveArticleLoading] = React.useState(false);
  const autocompleteLimit = 9999;

  const [editingArticle, setEditingArticle] = React.useState<Article>(
    getNewArticle(),
  );
  const [initialValue, setInitialValue] = React.useState<string>('');

  const [showDelete, setShowDelete] = React.useState(false);
  const [articleLock, setArticleLock] = React.useState<ArticleLock | null>(
    null,
  );
  const [showConfirmAction, setShowConfirmAction] = React.useState<
    | false
    | 'breaking-news'
    | 'disallow-comments'
    | 'sponsored'
    | 'no-crawlers'
    | 'free'
    | 'text-to-speech'
    | 'force-lock'
  >(false);
  const [coverDialogOpen, setCoverDialogOpen] = React.useState(false);
  const [attachmentDialogOpen, setAttachmentDialogOpen] = React.useState(false);
  const [selectedAttachment, setSelectedAttachment] = React.useState<
    ArticleAttachment | undefined
  >();

  const [showRemoveCover, setShowRemoveCover] = React.useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = React.useState<
    number | undefined
  >(undefined);
  const [authors, setAuthors] = React.useState<Author[]>([]);
  const [selectedAuthors, setSelectedAuthors] = React.useState<ArticleAuthor[]>(
    [],
  );

  const [tags, setTags] = React.useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<ArticleTag[]>([]);

  const styliseBBCode = React.useCallback((body?: string) => {
    // Makes bbcode look pretty for the rich text editor
    if (!body) {
      return '';
    }
    // Regex to find BBCode blocks not already wrapped in <span class='bbcode'>
    const regex =
      /(\[(\w+)(?:[^\]]*)\](?![^<]*<\/span>)([\s\S]*?)\[\/\2(?:[^\]]*)\])/gis;
    return body.replace(regex, "<span class='bbcode'>$&</span>");
  }, []);

  const removeStylisedBBCode = React.useCallback((body?: string) => {
    // Restores the original markup for saving
    if (!body) {
      return '';
    }
    const regex = /<span class=['"]bbcode['"]>([\s\S]*?)<\/span>/gi;
    return body.replace(regex, '$1');
  }, []);

  const fetchAuthorData = React.useCallback(
    (article?: Article) => {
      const existingAuthorIds =
        article?.articles_authors?.map((au) => au.author_id) ?? [];

      setLoadingAuthors(true);
      fetchAuthors('', 0, autocompleteLimit, true, existingAuthorIds)
        .then((d) => {
          setLoadingAuthors(false);
          setAuthors(d.data ?? []);
        })
        .catch((e) => {
          setLoadingAuthors(false);
        });
    },
    [setAuthors, autocompleteLimit, setLoadingAuthors],
  );

  const fetchTagData = React.useCallback(() => {
    setLoadingTags(true);
    fetchTags('', 0, autocompleteLimit)
      .then((d) => {
        setLoadingTags(false);
        setTags(d.data ?? []);
      })
      .catch((e) => {
        setLoadingTags(false);
      });
  }, [setTags, setLoadingTags, autocompleteLimit]);

  const fetchData = React.useCallback(() => {
    if (!id) {
      fetchAuthorData();
      fetchTagData();
      return;
    }
    setLoading(true);
    fetchArticle(parseInt(id))
      .then((d) => {
        setLoading(false);
        setEditingArticle(d);
        setInitialValue(styliseBBCode(d.body));
        setSelectedAuthors(
          d.articles_authors?.sort((au1, au2) =>
            au1.position > au2.position ? 1 : -1,
          ) ?? [],
        );
        setSelectedTags(
          d.articles_tags?.sort((au1, au2) =>
            au1.position > au2.position ? 1 : -1,
          ) ?? [],
        );
        fetchAuthorData(d);
        fetchTagData();
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [
    setLoading,
    setInitialValue,
    styliseBBCode,
    id,
    fetchAuthorData,
    fetchTagData,
  ]);

  React.useEffect(() => {
    fetchData();

    // Start all custom TinyMCE plugins
    CustomIcons();
    CustomPluginAttach();
    CustomPluginAudio();
    CustomPluginIFrame();
    CustomPluginInstagram();
    CustomPluginJwPlayer();
    CustomPluginQuote();
    CustomPluginTikTok();
    CustomPluginFacebook();
    CustomPluginTweet();
    CustomPluginYoutube();
    CustomPluginCompareSlider();
    CustomPluginEmailSubscription();
    CustomPluginRelated();
    CustomPluginSlider();
  }, [fetchData]);

  React.useEffect(() => {
    (window as any).tinymce_attachments =
      editingArticle?.attachments?.slice() ?? [];
  }, [editingArticle.attachments]);

  const moveAuthors = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSelectedAuthors((prevAuthors: ArticleAuthor[]) => {
        const newAuthors = update(prevAuthors, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevAuthors[dragIndex] as ArticleAuthor],
          ],
        });
        return newAuthors.map((author, index) => ({
          ...author,
          position: index,
        }));
      });
    },
    [],
  );

  const moveTags = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSelectedTags((prevTags: ArticleTag[]) => {
        const newTags = update(prevTags, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevTags[dragIndex] as ArticleTag],
          ],
        });
        return newTags.map((tag, index) => ({
          ...tag,
          position: index,
        }));
      });
    },
    [],
  );

  const doLockArticle = React.useCallback(
    async (id: number) => {
      if (id === -1) {
        // We do not need to lock new articles
        return;
      }
      const lockedArticle = await lockArticle(id);
      setArticleLock(lockedArticle);
    },
    [setArticleLock],
  );

  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    if (!editingArticle || !editingArticle.id) {
      return;
    }

    doLockArticle(editingArticle.id);
    ref.current = setInterval(() => {
      // Check every minute
      doLockArticle(editingArticle.id);
    }, 60 * 1000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [editingArticle, doLockArticle]);

  if (loading) {
    return <Skeleton variant="rectangular" height={500} />;
  }

  const authorOptions: ListOption[] = authors
    .map((a) => ({
      value: a.id,
      label: a.display_name ?? `Author: ${a.id.toString()}`,
    }))
    .filter(
      (ao) =>
        !selectedAuthors.find((selected) => selected.author_id === ao.value),
    );

  const tagOptions: ListOption[] = tags
    .map((t) => ({
      value: t.id,
      label: t.name ?? `Tag: ${t.id.toString()}`,
    }))
    .filter(
      (at) => !selectedTags.find((selected) => selected.tag_id === at.value),
    );

  // Build a url that points to the main website article page
  const articleLink = `${Config.WEBSITE_BASE_URL}/${slug(
    editingArticle.slug,
  )}.${editingArticle.id}`;

  return (
    <div style={{ paddingBottom: 75 }}>
      <Grid container spacing="16">
        <Grid item xs={8}>
          <div style={{ marginBottom: 16, overflow: 'visible' }}>
            <TextField
              fullWidth
              label="Headline"
              value={editingArticle.title ?? ''}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }
                const originalSlug = slugify(editingArticle.title ?? '');
                const newSlug = slugify(e.target.value);
                setEditingArticle((current) => ({
                  ...current,
                  title: e.target.value,
                  slug:
                    !editingArticle.slug || editingArticle.slug === originalSlug
                      ? newSlug
                      : current.slug,
                }));
              }}
              autoFocus
              placeholder="Article heading"
              type="text"
              inputProps={{
                style: {
                  backgroundColor: '#ffffff',
                },
              }}
              style={{ marginBottom: 12 }}
            />
            <TextField
              fullWidth
              label="Sub-headline"
              value={editingArticle.subheadline ?? ''}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }
                setEditingArticle((current) => ({
                  ...current,
                  subheadline: e.target.value,
                }));
              }}
              placeholder="Sub-headline"
              type="text"
              inputProps={{
                style: {
                  backgroundColor: '#ffffff',
                },
              }}
              style={{ marginBottom: 12 }}
            />
            <TextField
              helperText={
                editingArticle.id === -1 ? (
                  <span>Save the new article to get a link</span>
                ) : (
                  <a
                    href={articleLink}
                    style={{ color: '#444' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {articleLink}
                  </a>
                )
              }
              prefix=""
              fullWidth
              value={editingArticle.slug ?? ''}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }
                setEditingArticle((current) => ({
                  ...current,
                  slug: e.target.value,
                }));
              }}
              label="Slug"
              placeholder="Link (slug)"
              type="text"
              inputProps={{
                style: {
                  backgroundColor: '#ffffff',
                },
              }}
              style={{ marginBottom: 6 }}
            />
          </div>

          <ToggleButtonGroup style={{ marginBottom: 16 }} fullWidth>
            <ToggleButton
              value="breaking-news"
              selected={Boolean(editingArticle.is_breaking_news)}
              color="error"
              onChange={() => {
                setShowConfirmAction('breaking-news');
              }}
            >
              Breaking news
            </ToggleButton>
            <ToggleButton
              value="disallow-comments"
              color="info"
              selected={Boolean(editingArticle.disallow_comments)}
              onChange={() => {
                setShowConfirmAction('disallow-comments');
              }}
            >
              No comments
            </ToggleButton>

            <ToggleButton
              value="sponsored"
              color="info"
              selected={Boolean(editingArticle.is_sponsored)}
              onChange={() => {
                setShowConfirmAction('sponsored');
              }}
            >
              Sponsored
            </ToggleButton>

            <ToggleButton
              value="no-crawlers"
              color="info"
              selected={editingArticle.no_crawlers}
              onChange={() => {
                setShowConfirmAction('no-crawlers');
              }}
            >
              No crawlers
            </ToggleButton>

            <ToggleButton
              value="free"
              color="info"
              selected={editingArticle.is_free}
              onChange={() => {
                setShowConfirmAction('free');
              }}
            >
              Free article
            </ToggleButton>

            <ToggleButton
              value="text-to-speech"
              color="info"
              selected={editingArticle.is_polly}
              onChange={() => {
                setShowConfirmAction('text-to-speech');
              }}
            >
              Text-to-speech
            </ToggleButton>
          </ToggleButtonGroup>
          <Editor
            // TODO: Check why license key does not seem to be accepted
            disabled={saveArticleLoading}
            apiKey={Config.TINYMCE_API_KEY}
            licenseKey={Config.TINYMCE_API_KEY}
            init={{
              plugins: [
                'link',
                'lists',
                'table',
                'wordcount',
                'fullscreen',
                'advlist',
                'anchor',
                'charmap',
                'searchreplace',
                'tomAttach',
                'tomJwPlayer',
                'tomAudio',
                'tomIFrame',
                'tomQuote',
                'tomInstagram',
                'tomTikTok',
                'tomFacebook',
                'tomYoutube',
                'tomTweet',
                'tomRelated',
                'tomEmailSubscription',
                'tomCompareSlider',
                'tomSlider',
              ],
              menubar: false,
              branding: false,
              convert_urls: false,
              paste_as_text: true,
              invalid_elements: 'h1, br, blockquote',
              spellchecker_language: 'en_gb',
              advlist_number_styles: 'default',
              target_list: [
                { title: 'New Tab', value: '_blank' },
                { title: 'Same page', value: '_self' },
              ],
              height: 'calc(100vh - 250px)',
              //height: '100%',
              rel_list: [
                { title: 'n/a', value: '' },
                { title: 'nofollow', value: 'nofollow' },
              ],
              block_formats:
                'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4',
              noneditable_class: 'bbcode',
              icons: 'TOM_pack_1',
              content_style: 'span.bbcode { background-color: #dddcff }',
              toolbar1:
                'blocks | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | styleselect | outdent indent removeformat ',
              toolbar2:
                'undo redo pastetext superscript subscript hr strikethrough charmap | link unlink | anchor cleanup code table fullscreen forecolor searchreplace',
              toolbar3:
                'tomQuote tomAttach tomCompareSlider tomSlider tomAudio tomEmailSubscription tomTweet tomFacebook tomInstagram tomTikTok tomYouTube tomJwPlayer tomRelated tomIFrame',
            }}
            onEditorChange={(content, editor) => {
              const cleanContent = removeStylisedBBCode(content);
              setEditingArticle((article) => ({
                ...article,
                body: cleanContent, // Clean up for saving
              }));
            }}
            initialValue={initialValue}
          />
        </Grid>
        <Grid item xs={4}>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader
              title="Cover photo"
              style={{ paddingBottom: 0, marginBottom: 0 }}
            />
            <CardContent>
              <div
                style={{
                  backgroundColor: '#eee',
                  minHeight: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {editingArticle.cover ? (
                  <div>
                    <img
                      alt={editingArticle.cover.name}
                      src={editingArticle.cover.url}
                      style={{ display: 'block', maxWidth: '100%' }}
                    />
                    <Button
                      onClick={() => {
                        setCoverDialogOpen(true);
                      }}
                    >
                      Change
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setShowRemoveCover(true);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      setCoverDialogOpen(true);
                    }}
                  >
                    Upload a cover
                  </Button>
                )}
              </div>
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader
              title="Authors"
              style={{ marginBottom: 0, paddingBottom: 0 }}
              action={
                <React.Fragment>
                  <Button
                    size="small"
                    href="/access-controls#authors"
                    target="_blank"
                  >
                    Manage
                  </Button>
                  <IconButton
                    disabled={loadingAuthors}
                    size="small"
                    onClick={() => {
                      const existingAuthorIds =
                        editingArticle.articles_authors?.map(
                          (au) => au.author_id,
                        );
                      setLoadingAuthors(true);
                      fetchAuthors(
                        '',
                        0,
                        autocompleteLimit,
                        true,
                        existingAuthorIds,
                      )
                        .then((d) => {
                          setLoadingAuthors(false);
                          setAuthors(d.data ?? []);
                        })
                        .catch((e) => {
                          setLoadingAuthors(false);
                        });
                    }}
                  >
                    <Refresh fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            <CardContent style={{ paddingTop: 0 }}>
              {selectedAuthors.length > 0 && (
                <DndProvider backend={HTML5Backend}>
                  <List dense>
                    {selectedAuthors
                      .sort((a1, a2) => (a1.position > a2.position ? 1 : -1))
                      .map((selectedAuthor, index) => {
                        const authorName =
                          authors.find(
                            (sa) => sa.id === selectedAuthor.author_id,
                          )?.display_name ??
                          `Author ${selectedAuthor.author_id}`;
                        return (
                          <SortableListItem
                            itemType="author"
                            id={selectedAuthor.author_id}
                            index={index}
                            moveItem={moveAuthors}
                            removeItem={(itemId) => {
                              setSelectedAuthors((currentAuthors) => {
                                return currentAuthors.filter(
                                  (s) => s.author_id !== itemId,
                                );
                              });
                            }}
                            key={`selected_${index}`}
                            content={
                              <React.Fragment>
                                <Avatar
                                  style={{
                                    backgroundColor: `#${generateSeededColour({
                                      seed: authorName,
                                    })}`,
                                    width: 30,
                                    height: 30,
                                  }}
                                />
                                <span>{authorName}</span>
                              </React.Fragment>
                            }
                          />
                        );
                      })}
                  </List>
                </DndProvider>
              )}
              <Autocomplete
                value={null}
                disabled={loadingAuthors}
                selectOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Add author"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                fullWidth
                options={authorOptions}
                getOptionKey={(o) => o.value}
                clearOnBlur
                clearOnEscape
                autoHighlight
                isOptionEqualToValue={(option, value) => {
                  return false;
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return;
                  }
                  const lastPosition = selectedAuthors.length;
                  setSelectedAuthors((selected) => [
                    ...selected,
                    {
                      id: -1,
                      article_id: editingArticle.id,
                      position: lastPosition,
                      author_id: option?.value,
                    },
                  ]);
                  return;
                }}
              />
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader
              title="Tags"
              style={{ marginBottom: 0, paddingBottom: 0 }}
              action={
                <React.Fragment>
                  <Button size="small" href="/tags" target="_blank">
                    Manage
                  </Button>
                  <IconButton
                    disabled={loadingTags}
                    size="small"
                    onClick={() => {
                      setLoadingTags(true);
                      fetchTags('', 0, autocompleteLimit)
                        .then((d) => {
                          setLoadingTags(false);
                          setTags(d.data ?? []);
                        })
                        .catch((e) => {
                          setLoadingTags(false);
                        });
                    }}
                  >
                    <Refresh fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            <CardContent style={{ paddingTop: 0 }}>
              {selectedTags.length > 0 && (
                <DndProvider backend={HTML5Backend}>
                  <List dense>
                    {selectedTags
                      .sort((a1, a2) =>
                        a1.position > a2.position
                          ? 1
                          : a1.position === a2.position
                          ? 0
                          : -1,
                      )
                      .map((selectedTag, index) => {
                        const tagName =
                          tags?.find((sa) => sa.id === selectedTag.tag_id)
                            ?.name ?? `Tag ${selectedTag.tag_id}`;
                        return (
                          <SortableListItem
                            itemType="tag"
                            id={selectedTag.tag_id}
                            index={index}
                            moveItem={moveTags}
                            removeItem={(itemId) => {
                              setSelectedTags((currentTags) => {
                                return currentTags.filter(
                                  (s) => s.tag_id !== itemId,
                                );
                              });
                            }}
                            key={`selected_${index}`}
                            content={
                              <React.Fragment>
                                <TagIcon
                                  fontSize="small"
                                  color="disabled"
                                  style={{
                                    color: `#${generateSeededColour({
                                      seed: tagName,
                                    })}`,
                                  }}
                                />
                                <span>{tagName}</span>
                              </React.Fragment>
                            }
                          />
                        );
                      })}
                  </List>
                </DndProvider>
              )}
              <Autocomplete
                value={null}
                disabled={loadingTags}
                selectOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Add tag"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                fullWidth
                options={tagOptions}
                getOptionKey={(o) => o.value}
                clearOnBlur
                clearOnEscape
                autoHighlight
                isOptionEqualToValue={(option, value) => {
                  return false;
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return;
                  }
                  const lastPosition = selectedTags.length;
                  setSelectedTags((selected) => [
                    ...selected,
                    {
                      id: -1,
                      article_id: editingArticle.id,
                      position: lastPosition,
                      tag_id: option?.value,
                    },
                  ]);
                  return;
                }}
              />
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader
              title="Media"
              style={{ paddingBottom: 0, marginBottom: 0 }}
              action={
                <Button
                  type="button"
                  size="small"
                  onClick={() => {
                    setSelectedAttachment(undefined);
                    setAttachmentDialogOpen(true);
                  }}
                >
                  Add media
                </Button>
              }
            />
            <CardContent>
              {editingArticle.attachments?.map((attachment, index) => (
                <div
                  key={`attachment_${attachment.id}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'stretch',
                    minHeight: '50px',
                    marginBottom: 8,
                  }}
                >
                  {attachment.type === AttachmentType.IMAGE ? (
                    <div
                      style={{
                        flexBasis: 60,
                        marginRight: 16,
                        boxShadow: '0px 0px 2px #000',
                        backgroundPosition: '50% 50%',
                        backgroundColor: '#ccc',
                        backgroundSize: 'cover',
                        backgroundImage: `url('${attachment.url}')`,
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        flexBasis: 60,
                        marginRight: 16,
                        backgroundColor: '#eee',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {getAttachmentIcon(attachment.type)}
                    </div>
                  )}
                  <Typography
                    variant="h6"
                    style={{ alignContent: 'center', flex: 1 }}
                  >
                    {attachment.caption ?? 'No caption'}
                  </Typography>
                  <div
                    style={{
                      flexBasis: 80,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedAttachment(attachment);
                        setAttachmentDialogOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setAttachmentToDelete(attachment.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <CardHeader
              title="Contributors"
              style={{ marginBottom: 0, paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0 }}>
              {editingArticle.contributors &&
                editingArticle.contributors.length > 0 && (
                  <List dense>
                    {editingArticle.contributors.map((contributor, index) => {
                      return (
                        <ListItem
                          key={`selected_${index}`}
                          style={{ paddingLeft: 0 }}
                        >
                          <ListItemText
                            primary={
                              <TextField
                                value={contributor.credits}
                                onChange={(e) => {
                                  setEditingArticle((article) => {
                                    const newContributors =
                                      article.contributors?.slice();
                                    if (!newContributors) {
                                      return article;
                                    }
                                    newContributors[index].credits =
                                      e.target.value.trim();
                                    return {
                                      ...article,
                                      contributors: newContributors,
                                    };
                                  });
                                }}
                                fullWidth
                                placeholder="Credits"
                                inputProps={{
                                  style: {
                                    padding: '4px 8px',
                                  },
                                }}
                              />
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={() => {
                                setEditingArticle((a) => {
                                  const newContributors = a.contributors
                                    ? a.contributors
                                    : [];
                                  const thisIndex = newContributors.findIndex(
                                    (c) => c.id === contributor.id,
                                  );
                                  if (thisIndex < 0) {
                                    return a;
                                  }
                                  newContributors.splice(thisIndex, 1);
                                  return {
                                    ...a,
                                    contributors: newContributors,
                                  };
                                });
                              }}
                              size="small"
                              style={{ marginLeft: 8 }}
                            >
                              <Close />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              <Button
                type="button"
                size="small"
                onClick={() => {
                  setEditingArticle((a) => {
                    const newContributors = a.contributors
                      ? a.contributors
                      : [];
                    newContributors.push({
                      id: (newContributors.length + 1) * -1,
                      credits: '',
                    });
                    return {
                      ...a,
                      contributors: newContributors,
                    };
                  });
                }}
              >
                Add contributor
              </Button>
            </CardContent>
          </Card>
          <Card>
            <CardContent style={{ paddingTop: 24 }}>
              <TextField
                fullWidth
                value={editingArticle.ad_keyword ?? ''}
                label="Ad Keywords"
                placeholder="Ad Keywords"
                onChange={(e) => {
                  setEditingArticle((article) => ({
                    ...article,
                    ad_keyword: e.target.value,
                  }));
                }}
                style={{ marginBottom: 8 }}
              />
              <TextField
                fullWidth
                value={editingArticle.redirect_url ?? ''}
                label="Redirect"
                placeholder="Redirect url"
                onChange={(e) => {
                  setEditingArticle((article) => ({
                    ...article,
                    redirect_url: e.target.value,
                  }));
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {editingArticle && articleLock && articleLock.username !== user?.email ? (
        <ArticleLockedBar
          articleLock={articleLock}
          forceLock={() => {
            setShowConfirmAction('force-lock');
          }}
        />
      ) : (
        <ArticlePublishBar
          article={editingArticle}
          loading={saveArticleLoading || loading}
          onDelete={() => setShowDelete(true)}
          onSave={async () => {
            if (!editingArticle) {
              return;
            }
            if (editingArticle.redirect_url) {
              // Validate the redirect URL
              try {
                new URL(editingArticle.redirect_url);
              } catch (ex) {
                snackbar.error('The provided redirect URL is not valid.');
                return;
              }
            }
            setSaveArticleLoading(true);
            await updateArticle({
              article: {
                ...editingArticle,
                slug: slug(editingArticle.slug),
              },
              authors: selectedAuthors,
              contributors: editingArticle.contributors,
              tags: selectedTags,
              media: [],
              cover_id: editingArticle.cover?.id ?? false,
            })
              .then((data) => {
                snackbar.success('Article has been saved successfully');
                if (editingArticle.id === -1) {
                  setEditingArticle((a) => ({
                    ...a,
                    id: data.id,
                  }));
                }
                setInitialValue(styliseBBCode(editingArticle.body));
                if (data.status !== ArticleStatus.DRAFT) {
                  history.push('/articles');
                }
              })
              .catch((error) => {
                if (
                  error instanceof AxiosError &&
                  error.response?.data.message
                ) {
                  snackbar.error(error.response.data.message);
                  return;
                }
                snackbar.error('Unknown error while saving');
              })
              .finally(() => {
                setSaveArticleLoading(false);
              });
          }}
          onChangeStatus={(newStatus: number) => {
            setEditingArticle((a) => ({
              ...a,
              status: newStatus,
            }));
          }}
          onChangePublishDate={(newDate: Date) => {
            setEditingArticle((a) => ({
              ...a,
              date: newDate,
            }));
          }}
        />
      )}

      {showConfirmAction && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          title={`Please confirm your action`}
          content={`Are you sure you want to toggle ${showConfirmAction}?`}
          onCancel={() => setShowConfirmAction(false)}
          onConfirm={async () => {
            switch (showConfirmAction) {
              case 'breaking-news':
                setEditingArticle((article) => ({
                  ...article,
                  is_breaking_news: !article.is_breaking_news,
                }));
                break;
              case 'disallow-comments':
                setEditingArticle((article) => ({
                  ...article,
                  disallow_comments: !article.disallow_comments,
                }));
                break;
              case 'sponsored':
                setEditingArticle((article) => ({
                  ...article,
                  is_sponsored: !article.is_sponsored,
                }));
                break;
              case 'no-crawlers':
                setEditingArticle((article) => ({
                  ...article,
                  no_crawlers: !article.no_crawlers,
                }));
                break;
              case 'free':
                setEditingArticle((article) => ({
                  ...article,
                  is_free: !article.is_free,
                }));
                break;
              case 'text-to-speech':
                setEditingArticle((article) => ({
                  ...article,
                  is_polly: !article.is_polly,
                }));
                break;
              case 'force-lock':
                const lockedArticle = await lockArticle(
                  editingArticle.id,
                  true,
                );
                setArticleLock(lockedArticle);
                break;
            }
            setShowConfirmAction(false);
          }}
        />
      )}

      {showDelete && editingArticle && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          typePrompt={`Delete confirmed`}
          title="Delete confirmation"
          content={`Are you sure you want to delete the article '${editingArticle.title}'?`}
          yesText="Delete"
          noText="Cancel"
          onCancel={() => setShowDelete(false)}
          onConfirm={async () => {
            try {
              await deleteArticle(editingArticle);
              setEditingArticle((article) => ({
                ...article,
                status: ArticleStatus.DELETED,
              }));
              snackbar.success('Article marked as deleted');
            } catch (error) {
              if (error instanceof AxiosError && error.response?.data.message) {
                snackbar.error(error.response.data.message);
                return;
              }
              snackbar.error('Unknown error while saving');
            } finally {
              setShowDelete(false);
            }
          }}
        />
      )}

      {showRemoveCover && editingArticle && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          title={`Are you sure you want to remove the cover photo?`}
          yesText="Remove"
          noText="Cancel"
          onCancel={() => setShowRemoveCover(false)}
          onConfirm={async () => {
            setEditingArticle((article) => ({
              ...article,
              cover: undefined,
            }));
            setShowRemoveCover(false);
          }}
        />
      )}

      {attachmentToDelete && editingArticle && (
        <ConfirmDialog
          dialogProps={{
            open: true,
          }}
          title={`Are you sure you want to remove this attachment?`}
          yesText="Remove"
          noText="Cancel"
          onCancel={() => setAttachmentToDelete(undefined)}
          onConfirm={async () => {
            const newAttachments = editingArticle.attachments?.slice() ?? [];
            const attachmentIndex = newAttachments.findIndex(
              (a) => a.id === attachmentToDelete,
            );
            if (attachmentIndex === -1) {
              return;
            }
            newAttachments.splice(attachmentIndex, 1);
            setEditingArticle((article) => ({
              ...article,
              attachments: newAttachments,
            }));
            setAttachmentToDelete(undefined);
          }}
        />
      )}

      <AttachmentDialog
        isOpen={attachmentDialogOpen}
        attachment={selectedAttachment}
        setIsOpen={(isOpen) => {
          setAttachmentDialogOpen(isOpen);
        }}
        onSave={(newAttachment: ArticleAttachment) => {
          if (!editingArticle) {
            setAttachmentDialogOpen(false);
            return;
          }
          const existingAttachments = editingArticle.attachments?.slice() ?? [];
          const existingAttachmentIndex = existingAttachments.findIndex(
            (a) => a.id === newAttachment.id,
          );
          if (existingAttachmentIndex >= 0) {
            existingAttachments[existingAttachmentIndex] = newAttachment;
          } else {
            existingAttachments.push(newAttachment);
          }
          setEditingArticle((a) => ({
            ...a,
            attachments: existingAttachments,
          }));
          setAttachmentDialogOpen(false);
          setSelectedAttachment(undefined);
        }}
      />

      <AttachmentDialog
        isOpen={coverDialogOpen}
        attachment={editingArticle.cover}
        isCover={true}
        setIsOpen={(isOpen) => {
          setCoverDialogOpen(isOpen);
        }}
        onSave={(newAttachment: ArticleAttachment) => {
          if (!editingArticle) {
            setCoverDialogOpen(false);
            return;
          }

          setEditingArticle((ea) => ({
            ...ea,
            cover: newAttachment,
          }));
          setCoverDialogOpen(false);
          setSelectedAttachment(undefined);
        }}
      />
    </div>
  );
};

export function getAttachmentIcon(attachmentType: AttachmentType) {
  switch (attachmentType) {
    case AttachmentType.AUDIO:
      return <MusicNote />;
    case AttachmentType.VIDEO:
      return <VideoCameraBack />;
    default:
      return <FilePresent />;
  }
}
