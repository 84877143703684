import { styled } from '@mui/system';

import { CardContent, IconButton, Tooltip } from '@mui/material';
import { Edit, Warning } from '@mui/icons-material';
import { Article } from 'lib/Model/Article';
import { useDrag, useDrop } from 'react-dnd';
import * as React from 'react';

const DroppablePlaceholder = styled('div')(
  ({ theme }) => `
      background-color: #eee;
      height: 190px;
      justify-content: stretch;
      display: flex;
      position: relative;
    `,
);

const ArticleContainer = styled(CardContent)(
  ({ theme }) => `
    flex: 1;
    text-align: left;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    position: relative;
    padding: 10px 20px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;    
      background:linear-gradient(
        to bottom,
        rgba(0,0,0,0),
        rgba(0,0,0,0.95) 95%
      );
      z-index: 1;
    }
  `,
);

const TitleContainer = styled('div')(
  ({ theme }) => `
      font-weight: 500;
      color: #fff;
      z-index: 3;
      max-height: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      font-size: 16;
      &:hover {
        text-decoration: underline;
      }
  `,
);

const ActionsContainer = styled('div')(
  ({ theme }) => `
      position: absolute;
      right: 10px;
      top: 5px;
      z-index: 2;
      padding: 2px;
      display: flex;
      align-items: center;
  `,
);

interface Props {
  slotIndex: number;
  draggingIndex?: number;
  article?: Article;
  showDuplicateWarning?: boolean;
  height?: number;
  flexBasis?: string;
  fontSize?: number;
  onDrop: () => void;
  onEdit: (index: number) => void;
}

export const DraggableArticle: React.FC<Props> = ({
  slotIndex,
  article,
  height,
  flexBasis,
  fontSize,
  onDrop,
  onEdit,
  showDuplicateWarning,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'article',
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging();
      if (isDragging) {
        (window as any).swapLeft = slotIndex;
      }
      return { isDragging };
    },
    canDrag: article !== undefined,
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'article',
      drop: () => {
        (window as any).swapRight = slotIndex;
        onDrop();
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [article],
  );

  return (
    <DroppablePlaceholder
      style={{
        height,
        flexBasis,
      }}
      ref={drop}
    >
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
      <ArticleContainer
        ref={drag}
        style={{
          height,
          flexBasis,
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          backgroundImage: article?.cover
            ? `url('${article?.cover.url}')`
            : undefined,
        }}
      >
        <TitleContainer
          style={{ fontSize: fontSize }}
          onClick={() => {
            if (!article) {
              return;
            }
            window.open(`/articles/${article.id}`);
          }}
        >
          {!article ? 'Not set' : article.title}
        </TitleContainer>
        <ActionsContainer>
          <IconButton
            onClick={() => {
              onEdit(slotIndex);
            }}
          >
            <Edit />
          </IconButton>

          {showDuplicateWarning && (
            <Tooltip title="This article is featured twice">
              <Warning color="error" />
            </Tooltip>
          )}
        </ActionsContainer>
      </ArticleContainer>
    </DroppablePlaceholder>
  );
};
