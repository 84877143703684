import React from 'react';
import { AppBar, Button } from '@mui/material';
import { ArticleLock } from 'lib/Model/ArticleLock';

interface Props {
  articleLock: ArticleLock;
  forceLock: () => void;
}

export const ArticleLockedBar: React.FC<Props> = ({
  articleLock,
  forceLock,
}) => {
  return (
    <React.Fragment>
      <AppBar
        variant="elevation"
        position="fixed"
        color="error"
        style={{
          bottom: 0,
          top: 'auto',
          flexDirection: 'row',
          justifyContent: 'right',
          alignItems: 'center',
          padding: 8,
          gap: 32,
        }}
      >
        <div>
          Locked to: {articleLock.username}
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 8 }}
            onClick={() => {
              forceLock();
            }}
          >
            Override lock
          </Button>
        </div>
      </AppBar>
    </React.Fragment>
  );
};
