import { Permission } from './Permission';

export interface Role {
  id: number;
  name: string;
  guard_name: string;
  permissions?: Permission[];
  tags?: string[]; // TODO: check if we need to convert to a TAG model rather than string
  created_at: Date;
  updated_at?: Date;
}

export interface RolesResponse {
  success: boolean;
  message: string;
  data: Role[];
  total_results?: number; // TODO: API needs paging data, not nullable
}

export function getNewRole(): Role {
  return {
    id: -1,
    name: '',
    guard_name: 'api',
    permissions: [],
    tags: [],
    created_at: new Date(),
  };
}
