import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DelayedLinearProgress, SnackbarContext } from 'components';
import { Author, getNewAuthor } from 'lib/Model/Author';
import { createAuthor, fetchAuthor, updateAuthor } from 'lib/Service/Authors';
import { AxiosError } from 'axios';

interface Props {
  author: Author | undefined;
  open: boolean;
  onClose: () => void;
  onSaved: (savedAuthor: Author) => void;
}

const StyledAvatar = styled(Avatar)(
  ({ theme }) => `
      width: 60px;
      height: 60px;
      margin-right: ${theme.spacing(1)};
  `,
);

/*
This drawer pops out from the side and provides the CREATE/EDIT form for authors
*/
export const AuthorDrawer: React.FC<Props> = ({
  author: selectedAuthor,
  onClose,
  open,
  onSaved,
}) => {
  const theme = useTheme();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [editingAuthor, setEditingAuthor] = React.useState<Author>(
    selectedAuthor ?? getNewAuthor(),
  );

  React.useEffect(() => {
    setEditingAuthor(selectedAuthor ?? getNewAuthor());
  }, [selectedAuthor, setEditingAuthor]);

  const fetchData = React.useCallback(() => {
    if (!selectedAuthor) {
      return;
    }
    setLoading(true);
    fetchAuthor(selectedAuthor.id)
      .then((d) => {
        setLoading(false);
        setEditingAuthor(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, selectedAuthor, setEditingAuthor]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => {
        onClose();
      }}
    >
      <DelayedLinearProgress loading={loading} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setLoading(true);
          try {
            if (selectedAuthor) {
              await updateAuthor(editingAuthor);
            } else {
              await createAuthor(editingAuthor);
            }
            onSaved(editingAuthor);
            onClose();
            snackbar.success('Changes saved successfully');
          } catch (error) {
            if (error instanceof AxiosError && error.response?.data.message) {
              snackbar.error(error.response.data.message);
              return;
            }
            snackbar.error('Unknown error while saving');
          } finally {
            setLoading(false);
          }
        }}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Grid container spacing={1} style={{ maxWidth: 800 }}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <StyledAvatar src={editingAuthor?.picture} />
              <Box>
                <Typography variant="h6">
                  {editingAuthor?.id === -1 ? 'Add' : 'Edit'} author
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Display name"
                InputLabelProps={{
                  shrink: Boolean(editingAuthor?.display_name),
                }}
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    display_name: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.display_name ?? ''}
                required
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Bio"
                InputLabelProps={{
                  shrink: Boolean(editingAuthor?.description),
                }}
                multiline
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    description: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.description ?? ''}
                margin="none"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email address"
                InputLabelProps={{
                  shrink: Boolean(editingAuthor?.email),
                }}
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    email: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.email ?? ''}
                type="email"
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Website"
                InputLabelProps={{ shrink: Boolean(editingAuthor?.website) }}
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    website: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.website ?? ''}
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Facebook"
                InputLabelProps={{ shrink: Boolean(editingAuthor?.facebook) }}
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    facebook: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.facebook ?? ''}
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Instagram"
                InputLabelProps={{ shrink: Boolean(editingAuthor?.instagram) }}
                onChange={(e) => {
                  if (!editingAuthor) {
                    return;
                  }
                  setEditingAuthor((r) => ({
                    ...r!,
                    instagram: e.target?.value ?? '',
                  }));
                }}
                value={editingAuthor?.instagram ?? ''}
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                style={{
                  marginBottom: theme.spacing(1),
                  paddingLeft: theme.spacing(1),
                }}
                control={
                  <Checkbox
                    checked={Boolean(editingAuthor.active)}
                    onChange={(e) => {
                      if (!editingAuthor) {
                        return;
                      }
                      setEditingAuthor((r) => ({
                        ...r!,
                        active: e.target?.checked ? 1 : 0,
                      }));
                    }}
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                style={{
                  marginBottom: theme.spacing(1),
                  paddingLeft: theme.spacing(1),
                }}
                control={
                  <Checkbox
                    checked={Boolean(editingAuthor.visible)}
                    onChange={(e) => {
                      if (!editingAuthor) {
                        return;
                      }
                      setEditingAuthor((r) => ({
                        ...r!,
                        visible: e.target?.checked ? 1 : 0,
                      }));
                    }}
                  />
                }
                label="Visible"
              />
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: theme.spacing(1),
              paddingTop: theme.spacing(1),
            }}
          >
            <Button
              variant="text"
              type="submit"
              color="primary"
              disabled={loading}
            >
              Save
            </Button>{' '}
            <Button
              variant="text"
              type="button"
              color="inherit"
              onClick={() => onClose()}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};
