import { parseISO } from 'date-fns';
import { getApiClient } from 'lib/Helper/Api';
import {
  Article,
  ArticleAttachment,
  ArticleAuthor,
  ArticleContributor,
  ArticlesResponse,
  ArticleTag,
  HighlightGroupResponse,
} from 'lib/Model/Article';
import { ArticleLock } from 'lib/Model/ArticleLock';
import QueryString from 'qs';
import { Crop } from 'react-image-crop';

interface ArticlesListParams {
  query: string;
  tagIds?: number[];
  authorIds?: number[];
  statusIds?: number[];
  scheduledOnly?: boolean;
}

export function fetchArticles(
  params: ArticlesListParams,
  pageNumber: number,
  itemsPerPage: number,
): Promise<ArticlesResponse> {
  return getApiClient()
    .get(`/api/articles`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        ...params,
      },
    })
    .then((d) => {
      const articles = d.data.data.map((u: any) => hydrateArticle(u));
      return {
        ...d.data,
        data: articles,
      };
    });
}

export function fetchHighlights(
  group: string,
): Promise<HighlightGroupResponse> {
  return getApiClient()
    .get(`/api/highlights`, {
      params: {
        group,
      },
    })
    .then((d) => {
      const articles = d.data.data.map((u: any) => hydrateArticle(u));
      return {
        ...d.data,
        data: articles,
      };
    });
}

export function fetchArticle(id: number): Promise<Article> {
  return getApiClient()
    .get(`/api/articles/${id}`)
    .then((d) => hydrateArticle(d.data.data));
}

export function lockArticle(id: number, force?: boolean): Promise<ArticleLock> {
  return getApiClient()
    .post(
      `/api/articles/${id}/lock`,
      {
        force: force ?? false,
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((d) => hydrateArticleLock(d.data?.data));
}

export function createArticle(article: Article): Promise<Article> {
  // TODO: Remove this to use UPDATE as well ?
  return getApiClient()
    .post(`/api/articles`, QueryString.stringify(article), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => hydrateArticle(d.data.data));
}

/*
 * A note about authors and tags; empty array means clearing.
 * Not providing the parameter means not modifying them at all.
 */
interface ArticlePostContent {
  article: Article;
  authors?: ArticleAuthor[];
  contributors?: ArticleContributor[];
  tags?: ArticleTag[];
  cover_id?: number | false; // False means remove cover, undefined do nothing
  media?: ArticleAttachment[];
}

export function updateArticle(
  postContent: ArticlePostContent,
): Promise<Article> {
  return getApiClient()
    .put(
      `/api/articles/${postContent.article.id}`,
      QueryString.stringify(
        {
          ...postContent.article,
          authors: postContent.authors,
          tags: postContent.tags,
          contributors: postContent.contributors,
          cover_id: postContent.cover_id,
        },
        {
          allowEmptyArrays: true,
        },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then((d) => hydrateArticle(d.data.data));
}

export function deleteArticle(article: Article): Promise<Article> {
  return getApiClient()
    .delete(`/api/articles/${article.id}`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })
    .then((d) => {
      return d.data.data;
    });
}

function hydrateArticle(data: any): Article {
  return {
    ...data,
    date: data.date ? parseISO(data.date) : undefined,
  };
}

function hydrateArticleLock(data: any): ArticleLock {
  return {
    ...data,
    date: data.created ? parseISO(data.created) : undefined,
  };
}

/*
 * A note about authors and tags; empty array means clearing.
 * Not providing the parameter means not modifying them at all.
 */
interface HighlightsPostContent {
  order: number;
  article_id: number | undefined;
}

export function updateHighlightGroup(
  group: string,
  postContent: HighlightsPostContent[],
): Promise<boolean> {
  return getApiClient()
    .put(
      `/api/highlights/${group}`,
      QueryString.stringify(
        { articles: postContent },
        {
          allowEmptyArrays: true,
        },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then((d) => true); // TODO: handle response properly
}

interface AttachmentUploadOptions {
  id?: number;
  file?: any;
  type: number;
  onUploadProgress?: (progressEvent: any) => void;
  isCover?: boolean;
  caption?: string | null;
  cropData?: Crop | undefined;
}

export function uploadAttachment({
  id,
  file,
  type,
  onUploadProgress,
  isCover,
  caption,
  cropData,
}: AttachmentUploadOptions): Promise<ArticleAttachment | undefined> {
  const formData = new FormData();

  if (id) {
    formData.append('id', id.toString());
  }

  if (file) {
    formData.append('attachment', file);
  }

  formData.append('type', type.toString());
  formData.append('is_cover', isCover ? '1' : '0');
  formData.append('caption', caption ?? '');
  if (cropData) {
    formData.append('cropData', JSON.stringify(cropData));
  }

  return getApiClient()
    .post(`/api/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .then((d) => (d.data.data as ArticleAttachment) ?? undefined);
}
