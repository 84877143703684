import { Computer, PhoneAndroid, TabletAndroid } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface Props {
  id: number;
}
export const ArticlePreview: React.FC<Props> = ({ id }) => {
  const previewLink = `https://timesofmalta.com/admin/articles/preview/${id}`;
  const [previewMediaQuery, setPreviewMediaQuery] = React.useState<
    'desktop' | 'tablet' | 'mobile'
  >('desktop');

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    if (isMobile || isTablet) {
      setPreviewMediaQuery(isMobile ? 'mobile' : 'tablet');
    }
  }, [isMobile, isTablet, setPreviewMediaQuery]);

  const iFrameWidth = getFrameWidth();

  return (
    <Card elevation={0}>
      <CardHeader
        title="Article preview"
        action={
          !isMobile &&
          !isTablet && (
            <ToggleButtonGroup
              value={previewMediaQuery}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newAlignment: 'desktop' | 'tablet' | 'mobile' | null,
              ) => {
                setPreviewMediaQuery(newAlignment ?? 'desktop');
              }}
              aria-label="text alignment"
            >
              <ToggleButton value="mobile">
                <PhoneAndroid />
              </ToggleButton>
              <ToggleButton value="tablet">
                <TabletAndroid />
              </ToggleButton>
              <ToggleButton value="desktop">
                <Computer />
              </ToggleButton>
            </ToggleButtonGroup>
          )
        }
      />
      <div style={{ paddingBottom: 75, textAlign: 'center' }}>
        <iframe
          src={previewLink}
          title="Article preview"
          style={{
            height: 'calc(100vh - 75px)',
            width: iFrameWidth ?? '100%',
            margin: '0px auto',
            border: 0,
          }}
        />
      </div>
    </Card>
  );

  function getFrameWidth() {
    switch (previewMediaQuery) {
      case 'tablet':
        return 800;
      case 'mobile':
        return 400;
    }
    return undefined;
  }
};
