import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  PageableTable,
  PageableTableRow,
  PopperDropdownMenu,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Search } from '@mui/icons-material';
import { fetchAuditLogs } from 'lib/Service/AuditLogs';
import { useDebounce } from 'hooks';
import { AuditLog, AuditLogsResponse } from 'lib/Model/AuditLog';
import { format } from 'date-fns';
import { DateTimeFormat } from 'config';

/*
This table renders the audit logs
*/
export const AuditLogsTable: React.FC = () => {
  const [textSearch, setTextSearch] = React.useState('');

  const [selectedLogEntry, setSelectedLogEntry] = React.useState<
    AuditLog | undefined
  >();

  const [queryResponse, setQueryResponse] = React.useState<
    AuditLogsResponse | undefined
  >();
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const debouncedTextSearch = useDebounce(textSearch, 500);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchAuditLogs(
      {
        query: debouncedTextSearch,
      },
      pageNumber + 1,
      pageSize,
    )
      .then((d) => {
        setLoading(false);
        setQueryResponse(d);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [setLoading, setQueryResponse, pageNumber, pageSize, debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = React.useMemo(() => {
    return [
      {
        key: 'date',
        label: 'Date',
        sortable: false,
        props: {
          style: { width: 60 },
        },
      },
      {
        key: 'table',
        label: 'Table',
        sortable: false,
      },
      {
        key: 'action',
        label: 'Action',
        sortable: false,
      },
      {
        key: 'user',
        label: 'User',
        sortable: false,
      },
      {
        key: 'data',
        label: '',
        sortable: false,
      },
    ] as PageableTableHeader[];
  }, []);

  const rows: PageableTableRow[] =
    queryResponse?.data?.map((l: AuditLog) => {
      return {
        key: `logentry_${l.id}`,
        cells: [
          {
            key: 'date',
            display: (
              <Typography>
                {format(l.created_at, DateTimeFormat.LONG_FRIENDLY)}
              </Typography>
            ),
          },
          {
            key: 'table',
            display: <Typography>{l.table}</Typography>,
          },
          {
            key: 'action',
            display: <Typography>{l.action}</Typography>,
          },
          {
            key: 'user',
            display: (
              <Typography>
                {l.user.name}
                <br />
                {l.user.email}
              </Typography>
            ),
          },
          {
            key: 'data',
            props: {
              style: {
                textAlign: 'right',
              },
            },
            display: (
              <PopperDropdownMenu
                menuItems={[
                  {
                    label: 'More detail',
                    onClick: () => setSelectedLogEntry(l),
                  },
                ]}
              />
            ),
          },
        ],
      } as PageableTableRow;
    }) ?? [];

  return (
    <Card>
      <CardHeader title="Audit log" />
      <Toolbar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type here to find audit log entry.."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value ?? '')}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <PageableTable
        columns={columns}
        rows={rows}
        loading={loading}
        pageNumber={pageNumber}
        rowCount={queryResponse?.meta?.total ?? rows.length}
        onChangePageSize={setPageSize}
        onChangePage={setPageNumber}
      />

      <Drawer
        open={Boolean(selectedLogEntry)}
        anchor="right"
        onClose={() => setSelectedLogEntry(undefined)}
      >
        <Box style={{ minWidth: '30vw', padding: 16 }}>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
          >
            {selectedLogEntry?.data}
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              paddingTop: 8,
            }}
          >
            <Button
              variant="text"
              color="inherit"
              onClick={() => setSelectedLogEntry(undefined)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Card>
  );
};
