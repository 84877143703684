import { parseISO } from 'date-fns';
import { getApiClient } from 'lib/Helper/Api';
import { AuditLog, AuditLogsResponse } from 'lib/Model/AuditLog';

interface AuditLogListParams {
  query: string;
  dateFrom?: Date;
  dateTo?: Date;
}

export function fetchAuditLogs(
  params: AuditLogListParams,
  pageNumber: number,
  itemsPerPage: number,
): Promise<AuditLogsResponse> {
  return getApiClient()
    .get(`/api/audit-logs`, {
      params: {
        page: pageNumber,
        per_page: itemsPerPage,
        ...params,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((d) => {
      const articles = d.data?.data.map((u: any) => hydrateAuditLog(u));
      return {
        ...d.data,
        data: articles,
      };
    });
}

function hydrateAuditLog(data: any): AuditLog {
  return {
    ...data,
    created_at: data.created_at ? parseISO(data.created_at) : undefined,
  };
}
