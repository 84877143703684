import * as React from 'react';
import { Button } from '@mui/material';
import { AttachmentType } from 'lib/Helper/Enums';

interface Props {
  label: string;
  onSelect: (files: any) => void;
  allowedAttachmentTypes: AttachmentType[];
  autoOpen?: boolean;
}

export const FileUploadButton: React.FC<Props> = ({
  label,
  onSelect,
  allowedAttachmentTypes,
  autoOpen,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const fileTypes: String[] = [];

  if (allowedAttachmentTypes?.includes(AttachmentType.IMAGE)) {
    fileTypes.push('image/*');
  }

  if (allowedAttachmentTypes?.includes(AttachmentType.VIDEO)) {
    fileTypes.push('video/*');
  }

  if (allowedAttachmentTypes?.includes(AttachmentType.AUDIO)) {
    fileTypes.push('audio/*');
  }

  if (allowedAttachmentTypes?.includes(AttachmentType.OTHER)) {
    fileTypes.push('application/msword');
    fileTypes.push('application/vnd.ms-excel');
    fileTypes.push('application/vnd.ms-powerpoint');
    fileTypes.push('text/plain');
    fileTypes.push('application/pdf');
  }

  React.useEffect(() => {
    if (!autoOpen) {
      return;
    }
    fileInputRef?.current?.click();
  }, [fileInputRef, autoOpen]);

  return (
    <React.Fragment>
      <Button onClick={() => fileInputRef!.current!.click()}>{label}</Button>
      <input
        onChange={(event) => {
          onSelect(event.currentTarget.files);
        }}
        multiple={false}
        accept={fileTypes.join(',')}
        ref={fileInputRef}
        type="file"
        hidden
      />
    </React.Fragment>
  );
};
